import React, { useState } from 'react'
import { Center, Stack, Text, Box, PrimaryButton } from '../common'
import {
  Form,
  Input,
  message,
  Modal,
  Table,
  InputNumber,
  notification,
} from 'antd'
import { Layout } from '../components/Layout'
import axios from 'redaxios'
import {
  API_URL,
  MOBILE_NO_REGEX,
  EMAIL_REGEX,
} from '../common/utils'
import { to } from 'await-to-js'
import { ZHelmet } from '../components/ZHelmet'

type API_RESPONSE = {
  data: {
    status: string
    message: string
    payload: any
    paymentLink: any
    reason: string
  }
}
const getPaymentDetails$$ = (agreementNo: string): Promise<API_RESPONSE> => {
  return axios({
    method: 'GET',
    baseURL: API_URL,
    url: '/v1/payment/details',
    params: {
      agreement_number: agreementNo,
    },
  })
}

const getLoanDetails$$ = (agreementNo: string): Promise<API_RESPONSE> => {
  return axios({
    method: 'GET',
    baseURL: API_URL,
    url: `/v1/loan/details`,
    params: {
      agreement_number: agreementNo,
    },
  })
}


const createOrder$$ = (
  fullname: string,
  contactNumber: string,
  emailAddress: string,
  amount: string,
  agreementNumber: string,
): Promise<API_RESPONSE> => {
  return axios({
    method: 'POST',
    baseURL: API_URL,
    url: '/v1/payment/create',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      orderAmount: amount,
      customerName: fullname,
      customerPhone: contactNumber,
      customerEmail: emailAddress,
      agreement_number: agreementNumber
    },
  })
}

// const verifyPayment$$ = (
//   razorPayId: string,
//   agreementNumber: string,
//   amount: number,
// ): Promise<API_RESPONSE> => {
//   return axios({
//     method: 'POST',
//     baseURL: API_URL,
//     url: 'v1/payment/razorpay/capture',
//     data: {
//       agreement_number: agreementNumber,
//       amount: amount,
//       razorpay_id: razorPayId,
//     },
//   })
// }

export default function ApplyForLoan(props) {
  
  const [open, setOpen] = React.useState(false)
  // const [snackbarMessage, setsnackbarMessage] = React.useState("")
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [orderId, setOrderId] = useState(0)

  const [paymentDetails, setPaymentDetails] = useState<{
    emi: number
    lpc: number
    bounceCharges: number
    agreementNumber: string
    contactNumber: string
    emailAddress: string
    fullName: string
    totalAmount: number
  } | null>(null)

  const getPaymentDetails = async (
    agreementNumber: string,
    contactNumber: string,
    emailAddress: string,
    fullName: string,
  ) => {
    setLoading(true)
    const [error, response] = await to(getLoanDetails$$(agreementNumber))
    setLoading(false)

    if (!response || error || !response.data.status) {
      console.log(response?.data.message)
      setPaymentDetails(null)
      return message.error(
        'Oops! Some error occurred in fetching your payment details. Please try again',
      )
    }

    console.log('getPaymentDetails', response)

    setPaymentDetails({
     // emi: response.data.payload.PaymentAmount,
      emi: response.data.payload.TotalDueAmount,
      //lpc: response.data.payload.EstimatedLPC,
      lpc: response.data.payload.LPIDues,
      //bounceCharges: response.data.payload.VASChargeDueAmount,
      bounceCharges: response.data.payload.TotalVASDues,
      agreementNumber,
      contactNumber,
      emailAddress,
      fullName,
      totalAmount:
        response.data.payload.TotalDueAmount +
        response.data.payload.LPIDues +
        response.data.payload.TotalVASDues,
    })
    setVisible(true)
  }

  // const verifyPayment = async (
  //   transactionId: string,
  //   agreementNumber: string,
  //   amount: number,
  // ) => {
  //   console.log('in verify payment method')
  //   setLoading(true)
  //   const [error, response] = await to(
  //     verifyPayment$$(transactionId, agreementNumber, amount),
  //   )
  //   setLoading(false)
  //   setPaymentDetails(null)
  //   if (!response || error || !response.data.status) {
  //     console.log(response?.data.message)
  //     return notification.error({
  //       message: 'Oops! Some error occurred',
  //       description:
  //         'In case of any concern please get in touch at info@zavronfinance.com',
  //     })
  //   }

  //   notification.success({
  //     message: 'Congratulations!',
  //     description: 'Your payment has been successfully recorded.',
  //   })
  //   form.resetFields()
  // }

  // const openRazorpayModal = (
  //   amount: number,
  //   contactNumber: string,
  //   fullName: string,
  //   agreementNumber: string,
  // ) => {
  //   const amountInPaisa = Number((amount * 100).toFixed(0))
  //   console.log('in open razor payment model')

  //   const options = {
  //     // DEV
  //     // key: "rzp_test_XB0Hfc0MxuFG0V", // DO NOT MESS WITH THIS!!!!

  //     // PROD
  //     key: 'rzp_live_8Ma6OsOMQcdWBs', // DO NOT MESS WITH THIS!!!!
  //     amount: amountInPaisa, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //     currency: 'INR',
  //     name: 'Zavron Finserv',
  //     description: 'EMI Transaction',

  //     image:
  //       'https://res.cloudinary.com/dhoiqmk4x/image/upload/v1595829219/ZCash_Logo_1_n2cdan.png',
  //     // order_id: "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  //     handler: function (response) {
  //       // alert(response.razorpay_payment_id)
  //       // alert(response.razorpay_order_id)
  //       // alert(response.razorpay_signature)
  //       verifyPayment(
  //         response.razorpay_payment_id,
  //         agreementNumber,
  //         amountInPaisa,
  //       )
  //       setVisible(false)
  //     },
  //     prefill: {
  //       name: fullName,
  //       contact: contactNumber,
  //     },
  //     notes: {
  //       Address: '125, Maharajbagh Rd, Civil Lines, Nagpur Maharashtra 440012',
  //       Platform: 'Website: zavronfinserv.com',
  //     },
  //     theme: {
  //       color: '#001f79',
  //     },
  //   }
  //   console.log('strtinb razor pay')
  //   // @ts-ignore
  //   const rzp1 = new window.Razorpay(options)
  //   rzp1.open()
  // }

  const handleFinish = (values: any) => {
    getPaymentDetails(
      values.agreementNumber,
      values.contactNumber,
      values.emailAddress,
      values.fullName,
    )
  }

  const getDataSource = () => {
    if (!paymentDetails) {
      return []
    }
    return [
      {
        title: 'Full Name',
        data: paymentDetails.fullName,
      },
      {
        title: 'Agreement Number',
        data: paymentDetails.agreementNumber,
      },
      {
        title: 'Contact Number',
        data: paymentDetails.contactNumber,
      },
      {
        title: 'Email Address',
        data: paymentDetails.emailAddress,
      },
      {
        title: 'EMI Amount',
        data: '₹ ' + paymentDetails.emi,
      },
      {
        title: 'Late Charges',
        data: `₹ ${paymentDetails.lpc}`,
      },
      {
        title: 'Bounce Charges',
        data: `₹ ${paymentDetails.bounceCharges}`,
      },
    ]
  }

  const handlePayForm = async ({ amount }: { amount: string }) => {
    console.log('handle pay form', paymentDetails)

    const [error, response] = await to(
      createOrder$$(
        paymentDetails?.fullName || '',
        paymentDetails?.contactNumber || '',
        paymentDetails?.emailAddress || '',
        amount,
        paymentDetails?.agreementNumber
      ),
    )
    console.log('------', error)
    if (!response || error) {
      console.log('in not response', response?.data.message)
      setOpen(true)
    } else {
      var res: any = response.data;
      if (res.status == true) {

        var payload = res.payload;
        const paymentOrderId = payload.payment_order_id; 
        //const paymentSessionId = 'ee3ew1212'; 
        var options = {
          "access_key": "access_key_6NGvG9LzMY6DW0DM",
          "order_id": paymentOrderId,
          // here callback_url and redirect as true as you want to open the Checkout in redirect mode
          "callback_url": "https://app.zavronfinserv.com/v1/payment/handleresponse",
          "redirect":true
        }
        window.checkout = new NimbblCheckout(options);
        window.checkout.open(paymentOrderId);
        //window.open(payload.paymentLink, "_self"); //old
      }else{
        if (res.status == false) {
          let today = new Date().getDate();

          if (today >= 1 && today <= 5) {
            return message.error(res.message ? res.message : "Action cannot be performed as NACH transactions are pending for clearance.")
          } else {
            return message.error(res.message)
          }
        }
      }
      // }
    }

    // openRazorpayModal(
    //   Number(amount),
    //   paymentDetails?.contactNumber || "",
    //   paymentDetails?.fullName || "",
    //   paymentDetails?.agreementNumber || ""
    // )
  }

  const [form] = Form.useForm()
  return (
    <Layout>
      <ZHelmet
        title="Pay EMI"
        description="Pay EMIs of your personal loan on time to maintain a good credit score which helps you to avail other business loans or used car loans easily without bad impact">
        <script src="https://api.nimbbl.tech/static/assets/js/checkout.js"/>
      </ZHelmet>
      <Modal
        onCancel={() => setVisible(false)}
        title={
          <Text style={{ marginBottom: 0 }} variant="p">
            Payment Details
          </Text>
        }
        visible={visible}
        footer={null}
        destroyOnClose={true}>
        <Table
          pagination={false}
          showHeader={false}
          columns={[
            {
              title: 'Title',
              key: 'Title',
              dataIndex: 'title',
            },
            {
              title: 'Data',
              key: 'data',
              dataIndex: 'data',
            },
          ]}
          dataSource={getDataSource()}
        />

        <Form
          style={{ marginTop: 40 }}
          layout="vertical"
          onFinish={handlePayForm}>
          <Form.Item
            label="Enter the amount to pay"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter a valid amount!',
              },
              {
                validator: (_, value, callback) => {
                  if (
                    value &&
                    Number(value) <= (paymentDetails?.totalAmount || 0)
                  ) {
                    callback()
                  } else {
                    callback(
                      'Please enter an amount less than EMI + Late charges + Bounce Charges',
                    )
                  }
                },
              },
            ]}>
            <InputNumber style={{ borderRadius: 5, width: '60%' }} size="large" />
          </Form.Item>
          <Form.Item>
            <PrimaryButton
              loading={loading}
              style={{ textAlign: 'center' }}
              htmlType="submit"
            //onSubmit={()=>handlePayForm}
            >
              Pay Now
            </PrimaryButton>
          </Form.Item>
        </Form>
      </Modal>
      <Center gutters="var(--s2)" maxWidth="var(--max-width)" andText intrinsic>
        {/* <Snackbar anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }} autoHideDuration={5000} onClose={() => setOpen(false)} open={open} message={snackbarMessage}></Snackbar>  */}
        <Stack space="var(--s3)">
          <Stack space="var(--s-2)">
            <Text variant="h2">Pay EMI</Text>
            <Text variant="h4" style={{ fontFamily: 'var(--body-font-family)' }}>
              Login to pay EMI or get EMI info
            </Text>
          </Stack>
          <Box>
            <Form form={form} onFinish={handleFinish} layout="vertical">
              <Form.Item
                label="Full Name"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your Full Name',
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>
              <Form.Item
                label="Contact Number"
                name="contactNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid contact number',
                  },
                  {
                    validator: (_, value, callback) => {
                      if (value && MOBILE_NO_REGEX.test(value)) {
                        callback()
                      } else {
                        callback('Please enter a valid contact number')
                      }
                    },
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid Email Address',
                  },
                  {
                    validator: (_, value, callback) => {
                      if (value && EMAIL_REGEX.test(value)) {
                        callback()
                      } else {
                        callback('Please enter a valid Email Address')
                      }
                    },
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>
              <Form.Item
                label="Agreement Number"
                name="agreementNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a agreement number',
                  },
                ]}>
                <Input style={{ borderRadius: 5 }} size="large" />
              </Form.Item>
              <Form.Item>
                <PrimaryButton
                  loading={loading}
                  style={{ width: '100%' }}
                  htmlType="submit">
                  Get EMI Info
                </PrimaryButton>
              </Form.Item>
            </Form>
          </Box>
        </Stack>
      </Center>
    </Layout>
  )
}
